#recipt-form__profile__container {
    padding: 1rem;
    .MuiGrid-item {
        padding: 0 !important;
    }
}
#receipt-form__container {
    .MuiFormControl-root {
        margin: 0;
        border-radius: 0;
        .MuiInputBase-root {
            border-radius: 0;
            padding: 0;
        }

        .css-1zuho2-MuiInputBase-input-MuiFilledInput-input {
            padding: 0px;
        }
        .css-1hejy8i-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
            padding: 0px;
        }
    }

    .MuiFormHelperText-root {
        margin: 0;
        height: 0;
    }
}
