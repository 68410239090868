#show-party__container {
    .label {
        font-weight: 600;
        font-size: 14px;
    }

    p {
        // font-size: 17px !important;
        font-weight: 600 !important;
    }

    span {
        font-weight: 600 !important;
        // font-size: 17px !important;
    }
}
