#document-details__container {
    .MuiInputBase-root {
        padding: 0px;
        .css-1zuho2-MuiInputBase-input-MuiFilledInput-input {
            padding: 0px;
        }
        .css-30d66i-MuiInputBase-root-MuiFilledInput-root {
            padding: 0;
        }
    }
}
