@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.RaLabeled-label {
    font-size: 13px !important;
}
.RaLayout-appFrame {
    margin-top: 0 !important;
}

.MuiTablePagination-root {
    background: #f0f0f0;
    border-radius: 0.3rem;
}

.MuiTableContainer-root {
    overscroll-behavior: contain;
}

.MuiCardContent-root {
    padding: 0rem !important;
}

.MuiDrawer-root > .MuiPaper-root {
    /* width: 0px !important; */
}

.RaSidebar-fixed {
    background-color: black;
    /* color: white !important; */
}

.RaSidebar-fixed > .MuiList-root > .MuiMenuItem-root {
    color: white;
}

.RaSidebar-fixed > .MuiList-root > .MuiMenuItem-root:hover {
    background-color: #f0f0f0;
    color: black;
}

.auto-complete > .MuiFormControl-root {
    width: 100%;
    margin: 0;
}

.MuiFormHelperText-root {
    margin: 0 !important;
    border: none;
    display: none;
}

.RaToolbar-defaultToolbar {
    width: 100%;
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}

.auto-complete > .MuiFormControl-root > .MuiInputBase-root {
    background-color: #fff;
    max-height: 10rem;
    padding: 0 !important;
    margin-left: 0.3rem;
    border-radius: 0.3rem;
    width: calc(100% - 0.6rem);
}

.auto-complete > .MuiFormControl-root > .MuiInputBase-root > input {
    padding: 0.3rem 1rem !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.receipt-create > .RaCreate-main > .MuiPaper-root {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.RaShow-card {
    box-shadow: none !important;
    background-color: #c0cdb4 !important;
    border-radius: 0;
}
