.print-form-container {
    padding: 1rem 2rem;

    .hidden {
        visibility: hidden;
    }

    .print-form-content {
        border: 1px solid black;
        padding: 1rem;
        .heading {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;

            .content {
                font-size: 1.1rem;
                font-weight: normal;

                &.underlined {
                    border-bottom: 1.5px solid black;
                }
            }
        }
        .main-heading {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        .address {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        .main-heading-2 {
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            &.underlined {
                border-bottom: 1.5px solid black;
            }
        }
        .content-heading {
            font-size: 1rem;
            font-weight: normal;
            margin: 0.5rem 0;
        }
        .print-form__value {
            border-bottom: 1.5px solid black;
            .form-content {
                font-size: 1rem;
                font-weight: bold;
                padding: 0 0.5rem;
            }
        }
        .total-amt {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 0.5rem 0;
            border: 1.5px solid black;
            padding: 0 1rem;
        }
        .party-name_footer {
            font-size: 1rem;
            font-weight: bold;
            margin: 0.5rem 0;
        }
    }
}
